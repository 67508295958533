<template>
    <div class="checkout-page">
        <GeekQooSearch></GeekQooSearch>
        <p class="item-title">
            <van-icon name="checked"/>
            <span>已完成</span>
        </p>
        <p class="item-number">订单号：{{ $route.params.id }}</p>
        <vue-qr class="item-qrcode" :text="String('http://cdrz.demo.qoomoe.com/order/'+$route.params.id+'/print')" :margin="0" colorDark="#333" colorLight="#fff"></vue-qr>
        <van-button block class="item-button" color="#F28246" type="primary" to="/">返回首页</van-button>
    </div>
</template>

<script>
// 引入mixins
import mixins_config from "@/mixins/config.js";
// 引入组件
import GeekQooSearch from "@/components/GeekQooSearch"
import vueQr from "vue-qr"

export default {
    name: "Checkout",
    mixins: [mixins_config],
    components: {
        GeekQooSearch,
        vueQr
    },
    data() {
        return {};
    },
    created() {
        this.app.tabActive = 2;
    },
    mounted() {

    },
    methods: {}
}

</script>

<style lang="scss">
.checkout-page {
    padding: 0 0 50px 0;

    .item-title {
        padding: 50px 0 0 0;
        text-align: center;

        i, span {
            display: inline-block;
            vertical-align: middle;
            color: #88CF65;
        }

        i {
            font-size: 45px;
        }

        span {
            margin-left: 15px;
            font-size: 32px;
        }
    }

    .item-number {
        margin-top: 30px;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        color: #2D2D2D;
    }

    .item-qrcode {
        display: block!important;
        width: 212px;
        height: 212px;
        margin: 20px auto 0 auto;
    }
    .item-button{
        width: 299px;
        margin: 30px auto 0 auto;
    }
}
</style>